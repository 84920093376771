import * as React from 'react'
import { useDispatch } from 'react-redux'
import ScrollAnimation from 'react-animate-on-scroll'
import { FiArrowRight } from 'react-icons/fi'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { set } from '../reducers/usecase'
import Layout from '../layouts/layout'
import Title from '../components/section/title'
import Breadcrumb from '../components/section/breadcrumb'
import * as styles from '../styles/css/product.module.css'

const Product = () => {
  const dispatch = useDispatch();
  const openLayer = e => {
    e.preventDefault()
    dispatch(set(e.target.dataset.param))
  }
  
  const { t } = useTranslation();
  const alphabox = {
    title: t('productElement1Title'),
    body: t('productElement1Body'),
    items: [
      t('productElement1Item1'),
      t('productElement1Item2'),
      t('productElement1Item3'),
    ]
  }
  return (
    <Layout>
      <div className="main-content">
        <div className="rwt-split-area">
          <div className="row pb--125">
            <Breadcrumb
              title={t('productTitle')}
              rootUrl="/"
              parentUrl="Home"
              currentUrl="Product"
              styles={styles.bg}
            />
          </div>
          <div className="container">
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <Title
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = "Product"
                    title = "Product"
                    description = {t('productBody')}
                  />
                </div>
              </div>
              {/* first */}
              <div className="rn-splite-style">
                <div className="split-wrapper split-right">
                  <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <div className="thumbnail image-left-content">
                          <img src="https://storage.googleapis.com/alphacode-company-image/alphaboxbg.jpg" alt={alphabox.title} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <h4 className="title">{alphabox.title}</h4>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <p className="description" dangerouslySetInnerHTML={{__html: alphabox.body}}></p>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <ul className="split-list">
                          {alphabox.items.map( (item , i) => (
                            <li key={i}>- {item}</li>
                          ))}
                          </ul>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                          className="mt--10">
                          <Link className="btn-default btn-small btn-border btn-icon" to="#" data-param="alphabox" onClick={openLayer}>View More <i className="icon"><FiArrowRight /></i></Link>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div className="flex-center ptb--50">
                  <a className="btn-default round" href="/offering"><span>{t('viewOffering')}</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Product
